.coursepage{
	padding: 11% 0 5% 0; 
	background-image: url(../../assets/images/dance.jpg);
	background-image: linear-gradient(#6274ff,#000);
	background-size: cover;
	font-family: 'ubuntu';
	.coursetitle{
		font-size: 49px !important;
		font-weight: bold;
		// text-align: center;
		color: #fff;
	}
	.coursepara{
		// text-align: center;
		color: #fff;
		font-size: 20px !important;
	}
}

.youtube{
	position: relative;
	padding-top: 50px 0 50px 0;
	background: #000;
	// -ms-transform: rotate(45deg); /* IE 9 */
 //  	-webkit-transform: rotate(45deg); /* Safari */
 //  	transform: rotate(45deg);
 //  	overflow: hidden;
}
.coursetitle2{
		padding: 70px 0 40px 0;
		font-size: 30px !important;
		font-weight: bold;
		text-align: center;
		color: #fff;
	}

.instructor{
	background-image: linear-gradient(#000,#303a52,#000);
	// padding: 0px 0 40px 0;
	.courseinstructor{
		border-radius: 50%;
		max-width: 190px;
		height: 190px;
		display: block;
		margin-left: auto;
		margin-right: auto;
		}

	#name{
		color: #fff;
		text-align: center;
		font-family: 'ubuntu';
		font-weight: bold;
	}
	p {
		font-size: 18px;
		font-weight: 200;
		line-height: 28px;
		color: #fff;
		font-family: 'ubuntu'
	}
}



.quote{
	font-size: 70px;
}
.testimonials{
	background-image: linear-gradient(#000,#303a52,#000);
	padding: 70px 0 40px 0;
	width: 100%;
	margin: 0;
	.card-deck{
		margin: 0!important;
	}
}