@import '../../../public/css/colors';

$theme: #212529;
$hover: #fe4a55;
.background{
    background: #fff;
}

.navbar-brand a{
    font-size: 1.5rem!important;
    font-weight: 900;
    color: $theme!important;
}
.navbar-expand-lg .navbar-nav .nav-link {
    color: $theme!important;
    font-size: 18px;
    font-weight: 600;
   
}
.nav-link:hover{
    color: $hover!important; 
}
.dropdown-menu{
    background-color: #fff;

    .dropdown-item{
        color: $theme;
    }
    .dropdown-item:hover, .dropdown-item:focus {
        color: $hover; 
        text-decoration: none;
        background-color: transparent;
        
    }
}

.navbar .navbar-nav .nav-item .dropdown-menu{
    border-top-color: #fe4a55;
}

.navbar-toggler{
    border-color: transparent!important;
    .navbar-toggler-icon{
        border: 2px solid #fff;
        
    }
    
}
.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    /* border-color: rgba(0, 0, 0, 0.1); */
}
@media (max-width: 900px) {
    
    .navbar,.dropdown-menu {
     overflow-y:auto; 
     max-height:100vh
    }
}
