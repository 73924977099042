.ps-block-sidebar {
    margin: 20px 25px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
}

.ps-block-title {
    text-align: center;
    padding: 1em;
    font-weight: 500;
    color: #515365;
    border-bottom: 1px solid rgba(128, 128, 128, 0.1);

}

.ps-block-title:hover {
    background-color: #000;

    border-radius: 10px;
    color: #fff;
}


@media screen and (max-width: 1200px) {
    .ps-block-title {
        padding: 0.6em;
    }

    .sidebar-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.profilePageBody {
  background-color: #edf2f6;
  width: 100%;

}


.profile-pageCard {
  background-color: #fff;
  padding: 2em 10%;
  margin: 20px 25px;
  border-radius: 5px;
}


.profilePageBody h3 {
  text-align: center;
  color: #5c5c5c;
}

.profile-username {
  text-align: center;
  color: #5c5c5c;
  margin-bottom: 2em;
}

.profile-formAdjust {
  margin: 0.1em;
}

.profile-username {
  text-align: center;
  color: #5c5c5c;
  // margin-bottom: 2em;
}

.profile-name {
  text-align: center;
  color: #5c5c5c;
  margin-bottom: 1em;
}

.profile-bio {
  color: #888da8;
  margin-bottom: 1em;
  text-align: center;
}

.profile-option {
  color: #5c5c5c;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
