.team{
	width: 100%;

	.team_img {
		// height: 160px;
		border-radius: 6px;
		overflow: hidden;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: -86px;
		img{
			max-width: 100%;
		}
	}

	.team_body {
		width: 100%;
		padding-top: 108px;
		padding-bottom: 24px;
		background: #FFFFFF;
		border-radius: 6px;
		box-shadow: 0px 1px 10px rgba(29,34,47,0.1);
		text-align: center;
		-webkit-transition: all 200ms ease;
		-moz-transition: all 200ms ease;
		-ms-transition: all 200ms ease;
		-o-transition: all 200ms ease;
		transition: all 200ms ease;

		.team_title a {
			cursor: pointer;
			font-family: 'Roboto Slab', serif;
			font-size: 20px;
			font-weight: 700;
			color: #384158;
			-webkit-transition: all 200ms ease;
			-moz-transition: all 200ms ease;
			-ms-transition: all 200ms ease;
			-o-transition: all 200ms ease;
			transition: all 200ms ease;
		}
		.team_title a:hover{
			color: #fe4a55;
		}
		.team_subtitle {
			font-size: 14px;
			font-weight: 400;
			color: #76777a;
			margin-top: 6px;
		}
		.team_social {
			margin-top: 16px;

			ul {
				list-style: none;
				margin-bottom: 0px;
				li {
					display: inline-block;
				}
			}

		}
	}


}

.team:hover .team_body {
	box-shadow: 0px 5px 40px rgba(29,34,47,0.15);
}
html body div#root div.App div div.container div.row div.col-sm-12.col-md-3.col-lg-3 div.team.p-4 div.team_body div.team_social ul li a i{
	padding: 4px;
	font-size: 10px;
}