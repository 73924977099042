@import '../../../public/css/colors';
.base-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    padding: 20px;
    // background: $theme;
    .login{
      background: #fff;
      box-shadow: 0px 3px 12px 0.8px rgba($color: #000000, $alpha: 0.5);
      padding: 20px;
      margin-top: 50px;
      border-radius:  10px;
    }
    
    .content {
      display: flex;
      flex-direction: column;
      
    }
  
    .footer {
      margin-top: 3rem;
    }
  }