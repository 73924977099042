
.section1 {
    position: relative;
    z-index: 1;
    background-image: linear-gradient(#6274ff,#000);
    background-image: url(../../assets/images/gray-bg.webp);
    background-position: bottom right;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;


}
.main-banner-content {
	max-width: 595px;
	position: relative;
	top: -60px;
}


.heading{
    padding-top: 20%;
    color: #221638;
    font-size: 48px;
    font-weight: 800;
    line-height: 1.3; 
    max-width: 595px   
}
.para1{
    line-height: 1.3;
    color: #606060;
    font-weight: 500;
}

.single-courses-box {
    margin-top: 0px;
	position: relative;
	border-radius: 5px;
	background-color: #ffffff;
	-webkit-box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
	box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
    .courses-image {
		border-radius: 5px 5px 0 0;
		position: relative;
		z-index: 1;


		.price {
			display: inline-block;
			color: #ffffff;
			position: absolute;
			right: 0;
			border-radius: 10px 0 0 0;
			bottom: 0;
			padding: 7px 20px 5px;
			text-align: center;
			font-size: 24px;
			font-weight: 800;
			background-color: #fe4a55;
		}
		.image {
			overflow: hidden;
			border-radius: 5px 5px 0 0;
			img {
				height: 250px;
				
				border: none;
				-webkit-border-radius: 0;
				border-radius: 0;
				-webkit-box-shadow: none;
				box-shadow: none;
				min-width: 100%;
    			min-height: 100%
			}
		}
	}
	.courses-content {
		padding: 20px;
		.course-author {

		    margin-bottom: 15px;
		    span a{
			    color: #fe4a55;
			}
		    img {
				width: 35px;
				height: 35px;
				margin-right: 10px;
				border-radius: 50%;

			}
		}

	}

}

.single-courses-box .courses-content h3 {
	margin-bottom: 10px;
	line-height: 1.3;
	font-size: 24px;
	font-weight: 800;
}


.single-courses-box .courses-content  p {
	color: #606060;
	margin-bottom: 15px;
	line-height: 1.7;
	font-size: 16px;
}
a:hover{
	color: #fe4a55!important;	
}
.courses-content > h3:nth-child(2) > a:nth-child(1){
	color: #221638;
}
.row .col-lg-6:nth-child(1) .single-courses-box {
	margin-top: 90px;
}

@media only screen and (max-width: 767px) {
	.heading {
		top: 0;
		margin-bottom: 15px;
		font-size: 25px;
	}
	

}


@media only screen and (min-width: 576px) and (max-width: 767px) {

	.heading {
		top: 0;
		padding-right: 0;
		max-width: 595px;
		margin-bottom: 15px;
		font-size: 30px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.heading {
		top: 0;
		padding-right: 0;
		position: relative;
		margin-bottom: 15px;
		font-size: 35px;
		max-width: 595px
	}
	
	.single-courses-box {
		margin-top: 30px !important;
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {

}

@media only screen and (min-width: 1200px) and (max-width: 1355px) {

}

@media only screen and (min-width: 1550px) {

}