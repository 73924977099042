
.aboutus{
   
    color: inherit;
    // background-image: linear-gradient(#6274ff,#000);
    // background-image: url(../../assets/images/gray-bg.jpg);
    // background-position: bottom right;
    // background-size: cover;
    // background-repeat: no-repeat;
    font-weight: 600;
    background-color: #f0d6cc;
    p{
    	color: inherit;
    	
    	text-align: left;
    	padding-top: 20px;
    }
    hr{
    	background-color: #221638;
    }
}

