@import '../../../public/css/colors';

.linktitle{
    background-image: #f2f2f2;
    padding: 100px;
   
}
html body div#root div.App div div.row.linktitle.text-center.mx-auto div.col-md-12.col-sm-12.col-lg-12 a{
	color: inherit;
}
.linkhead{
    font-size: 48px!important;
    font-weight: 700;
}


@media only screen and (max-width: 767px) {
	.linkhead{
	    font-size: 25px!important;
	    font-weight: 600;
	}
}


@media only screen and (min-width: 576px) and (max-width: 767px) {

	.linkhead{
	    font-size: 30px!important;
	    font-weight: 600;
	}

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.linkhead{
	    font-size: 35px!important;
	    font-weight: 600;
	}
}