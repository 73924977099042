@import '../../../public/css/colors';
.footer{
    background: $card;
    color: $head;
    .dropdown-item,{
        color: #e4e4e4 ;
        font-size: 1em;
    }
    .dropdown-item:hover, .dropdown-item:focus{
        color: #fe4a55;
        background-color: transparent;
    }
    .dropdown-header{
        font-size: 1em;
    }
}
.p-2 > div:nth-child(1) > h1:nth-child(1),.p-2 > p:nth-child(2),div.row:nth-child(3) > h5:nth-child(1),div.row:nth-child(3) > h6:nth-child(1){
    color: #fff!important;
}

ul{
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: 900;
}
    
i{
    background-color: #1e1d2f;
    border-radius: 5px;
    color: $head;
    padding: 10px ;
    margin: 5px;
}
i:hover{
    background-color: #fe4a55;
    transition: 0.6s;
}
a,a:hover{
    text-decoration: none;
    color: $head;
}