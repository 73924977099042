@import '../../../public/css/colors'; 



.button{
    text-align: center;
    margin-left: 200px;
}
.card-title{
    color: $card;
    font-size: 20px;
    font-weight: 900;
}
.card-text{
    color: $card;
}
.heading2{
    text-align: center;
    font-weight: 900;
}

.imageside{
    // margin-top: 50px;
    width: 70%;
    display: block;
    margin-left: auto;  
    margin-right: auto;
    
}
.text-top{
    margin: -40px 0 0 40px ;
    
}
.imgtopic{
    width: 100%;
    padding: 10px;
    
}
.updown{
    animation: up-down 2s ease infinite alternate-reverse both;
}
@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
.subtopic{
    text-align: center;
    color: $subtopic;
    font-weight: bold;
    transition: all .5s ;
}

.left-heading{
    

    color: $card;
    font-size: 50px;
    font-weight: 900;
    line-height: 1.3;
    
}
p{
    color: $card;
}

