.single-blog-post:hover {
	-webkit-box-shadow: 0 20px 65px rgba(0, 0, 0, 0.13);
	box-shadow: 0 20px 65px rgba(0, 0, 0, 0.13);
	transition: .5s  ;
}
.widget {
	-webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.05);
	box-shadow: 0 20px 50px rgba(0, 0, 0, 0.05);
	padding: 30px 25px;
	background-color: #ffffff;
	ul {
		padding-left: 0;
		list-style-type: none;
		margin: 0;

		li {
			position: relative;
			margin-bottom: 12px;
			overflow: hidden;
			color: #252525;
			padding-left: 18px;
			font-size: 14px;
			font-weight: 600;
			a{
				color: #000;
			}
		}
	}
	ul li::before {
		height: 8px;
		width: 8px;
		content: '';
		border-radius: 50%;
		left: 0;
		top: 6px;
		position: absolute;
		background: #fe4a55;

	}
	

	span.post-count {
		float: right;
		font-size: 13px;
	}
}

