
.center {
	text-align: center;
}
.contactpage{
    padding: 80px;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
	
}
.contactus{
	padding: 20px;
	background-color: #fbfbfb;
	border-radius: 10px;
}
.contactform{
    padding: 50px;
    label {
		font-size: 1em;
	    
	    font-family: 'ubuntu';
	}

	/* Add styles to 'input' and 'textarea' selectors */
	input[type=text][name=first_name],input[type=text][name=last_name],,input[type=email], textarea {
	    width: 100%;
	    padding: 12px;
	    border: 1px solid #ccc;
	    border-radius: 4px;
	    box-sizing: border-box;
	    margin-top: 6px;
	    margin-bottom: 16px;
	    resize: vertical;
	}

	/* Add styles to show 'focus' of selector */
	input[type=text]:focus,input[type=email]:focus, textarea:focus {
		border: 1px solid green;
	}

	
	input[type=submit] {
		background: #64B5F6;
		margin: 0 auto;
		outline: 0;
		color: white;
		border: 0;
		padding: 12px 24px;
		border-radius: 4px;
		transition: all ease-in-out 0.1s;
		position: relative;
		display: inline-block;
		text-align: center;
	}

	/* Add styles for 'focus' property */
	input[type=submit]:focus {
		background: #A5D6A7;
		color: whitesmoke;
	}

	/* Style 'hover' property */
	input[type=submit]:hover {
		background: #2196F3;
	}
}

.contact-info{
	.sub-title{
		color: #fe4a55;
	}
	padding-right: 15px;
	h2 {
		margin-bottom: 12px;
		font-size: 36px;
		font-weight: 800;
	}
	p {
		color: #606060;
		margin-bottom: 15px;
		line-height: 1.7;
		font-size: 16px;
	}
	ul {
		padding-left: 0;
		list-style-type: none;
		margin-top: 35px;
		margin-bottom: 0;
	}
	ul li {
		position: relative;
		margin-bottom: 35px;
		padding-left: 100px;
		.icon {
			width: 75px;
			
			border-radius: 3px;
			position: absolute;
			text-align: center;
			left: 0;
			font-size: 40px;
			-webkit-transition: 0.5s;
			transition: 0.5s;
			top: 0;
			i{
				width: 75px;
				
			}
		}
	}
	ul li p {
		font-weight: 600;
		max-width: 300px;
		margin-bottom: 0;
		margin-top: 2px;
		a:hover,a {
		color: #fe4a55 !important;
		}
	}
	
	ul li h3 {
		margin-bottom: 8px;
		font-size: 24px;
		font-weight: 800;
	}
}



 

//  @media only screen and (max-width: 767px) {
//  	ul li .icon {
// 			width: 45px;
// 			border-radius: 3px;
// 			position: absolute;
// 			text-align: center;
// 			left: 0;
// 			font-size: 20px;
// 			-webkit-transition: 0.5s;
// 			transition: 0.5s;
// 			top: 0;
// 			i{
// 				width: 45px;
				
// 			}
// 		}

// }
// @media only screen and (min-width: 576px) and (max-width: 767px) {
// 	ul li .icon {
// 			width: 55px;
		
// 			border-radius: 3px;
// 			position: absolute;
// 			text-align: center;
// 			left: 0;
// 			font-size: 50px;
// 			-webkit-transition: 0.5s;
// 			transition: 0.5s;
// 			top: 0;
// 			i{
// 				width: 55px;
				
// 			}
// 		}
// }

// @media only screen and (min-width: 768px) and (max-width: 991px) {
// 	ul li .icon {
// 			width: 65px;
			
// 			border-radius: 3px;
// 			position: absolute;
// 			text-align: center;
// 			left: 0;
// 			font-size: 60px;
// 			-webkit-transition: 0.5s;
// 			transition: 0.5s;
// 			top: 0;
// 			i{
// 				width: 65px;
				
// 			}
// 		}
// }