@import '../../../public/css/colors';
img{        
        object-fit: cover;
        // height: 250px;
    }
.card2{
    // padding: 0 5px 0 5px;
    border-color: $head;
    background-color: #f7f7f7;
    // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin: 20px;
    
    .courseimage{
		width: 30px !important;
		height: 30px !important;
		border-radius: 50%;
		}
	.teacher{
		padding-left: 10px;
		
	}
	.card-title{
		color: inherit !important;
		text-align: center;
	}
	.price{
		float: right;
		color: #fe4a55 !important;
		font-weight: bold;

	}
	.nameplate{
		margin-top: 20px;
		max-height: 45px;
	}

	.card-text {
		color: #586f6f;
		/* max-height: 45px; */
		min-height: 45px;
		text-align: center;
		font-size: 14px;
	}
	.btn{
		display: block!important;
		text-align: inherit;
		
	}
	// .btn:hover{
	// 	color: inherit!important;
	// }

	
}


.card2.card:hover {
		color: #fe4a55;
		border-color: #fe4a55;
}


.owl-carousel .owl-item img{
	display: initial;
	width: 350px;
}
