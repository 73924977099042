#hellopreloader {
  
  position: fixed;
  text-align: center;
  z-index: 9999999;
  
  width: 100%;
  height: 100%;
  min-width: 100%;
  background: #f2f2f2;
  opacity: .8;
}
.loader:before {
  box-sizing: border-box; 
  width: 60px;
  height: 60px;  
  border-radius: 50%;
  border: 3px solid #f2f2f2;
  border-top-color: #000; 
  border-bottom-color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -30px;
  margin-top: -80px;
  content: '';
  -webkit-animation: load3 1.4s infinite ease;
  animation: load3 1.4s infinite ease;
  
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
