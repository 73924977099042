@import '../../../public/css/colors';

.category{
    margin-top: 40px;
    background: $head;
    border-radius: 10px;
    transition: .5s;
    // position: relative;
    // z-index: 0;
}

.icon{
    color: #000;
    background: none;
    font-size: 20px;
    width: 100%;
    
    
    
}
.icons{
    text-align: center;
    padding: 10px;
}
h6{
    text-align: center;
    
}
.catinner{
    padding: 20px!important;
}
.icons:hover{
    background: #f2f2f2;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: all .5s;
}
// path{
//     text-align: center;
// }