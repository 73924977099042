/* _colors.scss */
$theme1: linear-gradient(#6274ff,#b956ff);
$head1: #fff; 
$card1: #4255A4;
$subtopic1: #6274ff;

$theme: linear-gradient(#6274ff,#000);
$head: #fff; 
$card: #000;
$subtopic: #000;
$sublink: rgba(255,255,255,0.2);