.serachicon{
    background: #fff;
    border-radius: none;
    color: #222;
    text-align: center;
    
}
.searchbar{
    width: 80%; 
    padding: 12px;
    border: none;
    // height: 50px;
    
}

.searchbar:focus-within, .searchbar:focus{
    // transition: all .5s;
   border: none!important;
}
input:focus, textarea:focus, select:focus{
    outline: none;
}
.searchbarcontainer{
    position: relative;
    z-index: 12;
    height: 48px;
    background : #fff;
    align-content: 'center';
    border-radius: 5px 5px ;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    // position: relative;
    // z-index: 5;
    
    #ul{
        background : #fff;
        position: relative;
        z-index: 5!important;
        list-style: none;
        border: 1px black;
        display: none;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        border-radius: 0 0 10px 10px;
        margin-top: -5px;
        li{
            padding: 5px 56px;
            transition: all .5s ;
            border-radius: 0px 0px 10px 10px;
            
        }
        li:hover{
            background : rgba($color: #000000, $alpha: .1);
            border-radius: 10px;
        }
    }
}
