@import '../../../public/css/colors';

// .search{
//     margin-top: 30px ;
// }


.heading1{
    padding-top: 10%;
    font-size: 3.5rem;
    font-weight: 900;
    line-height: 1.3;

}
.prise span {
	font-size: 1.2rem;
	font-weight: 400;
}
.text h3 {
	font-size: 2.2rem;
	color: #fff;
	line-height: 56px;
	font-weight: 400;
}
span.inactive {
	color: #919191;
	text-decoration: line-through;
	margin-right: 50px;
}
span.active {
	color: #fe4a55;
}
.categorypage{
	// background-image: url(../../assets/images/gray-bg.jpg);
 //    background-position: bottom right;
 //    background-size: cover;
 //    background-repeat: no-repeat;
    background-color: #eee1d8;
}
.subject h3 {
	font-size: 36px;
	font-weight: 400;
	color: #1F1F1F;
	margin-bottom: 10px;
	margin-top: 30px;
}
.subject p {
	font-size: 14px;
	font-weight: 300;
	// line-height: 28px;
	color: #919191;
}
.courseimg{
	margin-top: -120px;
	width: 100%;
}
.author_info {
	border: .5px solid #212529 !important;
	border-radius: 10px;
	padding: 30px;
	margin-top: 40px;
	margin-bottom: 40px;
	// .form-control:focus,.form-control{
	// 	background-color: #f2f2f2;
	// }
}
.author{
	width: 50px !important;
	height: 50px !important;
	border-radius: 50%;
}

.thumb{
	float: left;
}

@media screen and (max-width: 812px) {
	.courseimg{
		margin-top: 10px;
		width: 100%;
	}
}

@media only screen and (max-width: 767px) {
	.heading1 {
		top: 0;
		padding-top: 25%;
		margin-bottom: 15px;
		font-size: 30px;
	}
	

}
@media only screen and (min-width: 576px) and (max-width: 767px) {

	.heading1 {
		top: 0;
		padding-top: 25%;
		padding-right: 0;
		max-width: 595px;
		margin-bottom: 15px;
		font-size: 30px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.heading1 {
		top: 0;
		padding-top: 25%;
		padding-right: 0;
		position: relative;
		margin-bottom: 15px;
		font-size: 35px;
		max-width: 595px
	}

}