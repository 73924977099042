body {
  margin: 0;
  width: 100%;
  font-family: 'Nunito', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden!important;
  background-color: #f2f2f2!important;

  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	color: #221638!important;
	font-family: "Nunito", sans-serif;
}
a{
  transition: 0.5s;
}
