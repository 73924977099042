.MuiAlert-root {
  justify-content: center;
  font-size: 200%;
  margin-top: 25vh !important;
  margin-right: 2rem;
}

.response {
  display: flex;
  justify-content: center;
  font-size: 15px;
  margin-left: 0.5rem;
}

.email-message {
  font-size: 20px !important;
}
